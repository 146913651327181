import cx from 'classnames'
import { motion, AnimatePresence } from 'motion/react'
import { useContext, useEffect, useRef, useState } from 'react'

import { type SanityTeamMember } from '@data/sanity/queries/types/blocks'
import { fadeAnimation } from '@lib/animate'
import { getLinkPageUrl } from '@lib/routes'
import { StringsContext } from '@lib/strings-context'

import { ButtonVariant } from '@components/buttons/button'
import IconButton from '@components/buttons/icon-button'
import Photo from '@components/photo'
import SimpleLink from '@components/simple-link'

interface TeamMemberCardProps {
  teamMember: SanityTeamMember
  className?: string
}

interface PlaceholderImageProps {
  className?: string
}

const TeamMemberCard = ({ teamMember, className }: TeamMemberCardProps) => {
  const strings = useContext(StringsContext)

  const ref = useRef<HTMLDivElement>(null)
  const [isModalOpen, setIsModalOpen] = useState(false)

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (!isModalOpen) {
        return
      }

      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsModalOpen(false)
      }
    }

    window.addEventListener('click', handleClickOutside)

    return () => {
      window.removeEventListener('click', handleClickOutside)
    }
  }, [ref, isModalOpen])

  if (!teamMember) {
    return null
  }

  const { name, about, photo, blogAuthor } = teamMember

  const blogAuthorPageUrl = blogAuthor
    ? getLinkPageUrl('blogAuthor', blogAuthor.slug.current)
    : null
  const hasModal = about || blogAuthorPageUrl

  return (
    <div ref={ref} className={className}>
      <div className="flex flex-col relative">
        <button
          onClick={() => setIsModalOpen(true)}
          className={cx({
            'cursor-default': !hasModal,
          })}
        >
          {photo && <Photo image={photo} className="w-full" />}
          {!photo && <PlaceholderImage className="aspect-[2/3] bg-[#f5e6d0]" />}
        </button>

        {hasModal && (
          <AnimatePresence>
            {isModalOpen && (
              <motion.div
                initial="hide"
                animate="show"
                exit="hide"
                variants={fadeAnimation}
                className="absolute inset-0 h-full flex flex-col bg-pageBG bg-opacity-95 text-pageText p-3"
              >
                <div className="flex flex-col gap-y-4 mb-4 md:gap-y-8 md:mb-6">
                  <IconButton
                    icon="Cross"
                    variant={ButtonVariant.LINK}
                    className="text-sm self-end !p-0"
                    onClick={() => setIsModalOpen(false)}
                  />

                  {blogAuthorPageUrl && (
                    <SimpleLink
                      href={blogAuthorPageUrl}
                      className="text-xs self-start underline hover:no-underline"
                    >
                      {strings.teamMemberCardBlogArticles}
                    </SimpleLink>
                  )}
                </div>

                <p className="w-full text-sm overflow-y-auto scrollbar-pageBG">
                  {about}
                </p>
              </motion.div>
            )}
          </AnimatePresence>
        )}
      </div>

      <h5 className="p-3">{name}</h5>
    </div>
  )
}

// interface LinkProps {
//   children: ReactNode
//   href: string
//   openInNewTab?: boolean
// }

// const Link = ({ children, href, openInNewTab }: LinkProps) => {
//   const props = {
//     className: 'text-sm md:text-base',
//     variant: ButtonVariant.LINK,
//     icon: 'ArrowRight' as IconName,
//     iconAlignment: ButtonIconAlignment.LEFT,
//   }

//   if (openInNewTab) {
//     return (
//       <ButtonLink href={href} target="_blank" rel="noreferrer" {...props}>
//         {children}
//       </ButtonLink>
//     )
//   }

//   return (
//     <NextLink passHref legacyBehavior href={href}>
//       <ButtonLink {...props}>{children}</ButtonLink>
//     </NextLink>
//   )
// }

const PlaceholderImage = ({ className }: PlaceholderImageProps) => (
  <div className={cx('w-full', className)} />
)

export default TeamMemberCard
