import { useCallback, useContext, useEffect, useState } from 'react'

import {
  type OrderFinancialStatus,
  type OrderFulfillmentStatus,
} from '@data/shopify/storefront/types'
import { getShopifyUserOrders } from './shopify/graphql/customer'
import {
  getCustomerHasMoreOrders,
  getCustomerOrderCursor,
  parseCustomerOrders,
} from './shopify/customer'
import { useUser } from './auth'
import { ShopContext } from '@lib/shop-context'
import { LanguageContext } from './language-context'

export interface AddressFormValues {
  firstName: string
  lastName: string
  company: string
  address1: string
  address2: string
  city: string
  country: string
  zip: string
  phone: string
  isDefault: boolean
}

export interface UserAddress {
  id: string
  globalId?: number
  isDefault: boolean
  formatted: string[]
  values: AddressFormValues
}

export interface UserOrder {
  id: string
  date: string
  paymentStatus: OrderFinancialStatus | null
  fulfillmentStatus: OrderFulfillmentStatus
  total: number
  url: string
}

export interface UserProduct {
  id: number
  title: string
  slug: string
}

const listPageSize = 10

/**
 * User order list hook.
 */
export const useUserOrderList = () => {
  const { locale } = useContext(LanguageContext)
  const { shopifyStorefrontGraphQlClient } = useContext(ShopContext)
  const { user } = useUser()

  const [orders, setOrders] = useState<UserOrder[]>([])
  const [orderCursor, setOrderCursor] = useState<string | null>(null)
  const [hasMoreOrders, setHasMoreOrders] = useState(false)
  const [isLoadingOrders, setIsLoadingOrders] = useState(true)
  const [isInitialized, setIsInitialized] = useState(false)

  const loadOrders = useCallback(async () => {
    if (!user?.token) {
      return
    }

    if (!shopifyStorefrontGraphQlClient) {
      throw new Error('Shopify Storefront API client missing')
    }

    setIsLoadingOrders(true)

    const shopifyUserOrders = await getShopifyUserOrders(
      locale,
      shopifyStorefrontGraphQlClient,
      user.token,
      orderCursor,
      listPageSize
    )
    setOrders((orders) => [
      ...(orders ?? []),
      ...parseCustomerOrders(shopifyUserOrders),
    ])
    setOrderCursor(getCustomerOrderCursor(shopifyUserOrders) ?? null)
    setHasMoreOrders(getCustomerHasMoreOrders(shopifyUserOrders) ?? false)

    setIsLoadingOrders(false)
  }, [locale, orderCursor, shopifyStorefrontGraphQlClient, user?.token])

  // Initialize order list
  useEffect(() => {
    if (!isInitialized && user?.token) {
      // Load first page
      loadOrders()
      setIsInitialized(true)
    }

    if (isInitialized && !user?.token) {
      // Clear orders
      setOrders([])
      setOrderCursor(null)
      setHasMoreOrders(false)
      setIsInitialized(false)
    }
  }, [isInitialized, loadOrders, user?.token])

  return {
    orders,
    hasMoreOrders,
    loadOrders,
    isLoadingOrders,
  }
}
